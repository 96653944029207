<div class="uk-position-relative uk-visible-toggle" tabindex="-1" uk-slideshow="ratio: 8:2">
    <ul class="uk-slideshow-items">
        <li>
            <img src="../../assets/images/contact_1.jpg" alt="" uk-cover>
            <div class="uk-position-bottom uk-position-medium uk-text-left uk-light">
                <div class="uk-container uk-container-large">
                    <h1 class="uk-margin-remove qt-font-bold">Get In Touch</h1>
                    <p class="uk-margin-remove qt-color-white uk-visible@m">Have a question or want to learn more about how Qaptive can help you build your business? </p>
                </div>
            </div>
        </li>
    </ul>
    <div class="uk-light">
        <a class="uk-position-center-left uk-position-small uk-hidden-hover" href="#" uk-slidenav-previous uk-slideshow-item="previous"></a>
        <a class="uk-position-center-right uk-position-small uk-hidden-hover" href="#" uk-slidenav-next uk-slideshow-item="next"></a>
    </div>
</div>

<div class="uk-section uk-section-muted">
    <div class="uk-container uk-container-large">
        <div class="uk-child-width-expand@s uk-text-left uk-margin-large" uk-grid>
            <div>
                <div class="uk-text-left">
                    <h5 class="qt-color-dark qt-font-bold uk-text-uppercase uk-visible@m">&nbsp;</h5>
                    <h2 class="qt-font-weight-500 uk-margin-large uk-margin-remove-top uk-margin-remove-bottom">Contact us and we will follow up with you shortly.
                    </h2>
                </div>
            </div>
            <div> 
                <div class="uk-padding uk-padding-remove-bottom uk-padding-remove-left uk-padding-remove-right">
                    <h5 class="qt-color-dark qt-font-bold uk-text-uppercase">Qaptive Technologies Pvt. Ltd.</h5>
                    <ul class="uk-list uk-list-divider">
                        <li><span class="uk-text-bold" uk-icon="location"></span>
                            6A-3, Jyothirmaya IT Building, Infopark CSEZ Phase 2, <br>
                    Kakkanad, Kochi, Kerala, India - 682303 </li> 
                             <li><span class="uk-text-bold" uk-icon="location"></span>
                                Al Muftah Building,<br> 3rd Floor, Office No.6, Al Rayyan Road P.O. Box: 11126, Doha, Qatar.
    
                            </li> 
                        <li><span class="uk-text-bold" uk-icon="receiver"></span>
                            +91 484 4855790</li> 
                            <li><span class="uk-text-bold" uk-icon="phone"></span>
                                +91 974 609 4422</li> 
                        <li><span class="uk-text-bold" uk-icon="mail"></span>
                            info@qaptive.co.in</li> 
                        
                   </ul> 
                </div>
           
           </div>
        </div>
    </div>
</div>
 
<div class="uk-section uk-section-muted qt-bg-dark">
    <div class="uk-container uk-container-expand">
         <iframe width="100%" height="500" id="gmap_canvas" src="https://maps.google.com/maps?q=Qaptive%20Technologies&t=&z=13&ie=UTF8&iwloc=&output=embed" frameborder="0" scrolling="no" marginheight="0" marginwidth="0"></iframe> 
    </div>
</div>


<div class="uk-section uk-section-muted">
    <div class="uk-container uk-container-xsmall">
        <h2 class="qt-font-weight-500 uk-margin uk-margin-remove-top ">
            Quick Enquiry 
        </h2>
        <form [formGroup]="contactFormGroup" #contactForm="ngForm" (ngSubmit)="onSubmit(contactForm)"> 
            <div class="uk-child-width-expand@s uk-text-left" uk-grid>
                <div>
                    <div class="uk-text-left">
                        <div class="uk-margin">
                            <mat-form-field>
                                <input required matInput formControlName="name"  class="uk-input" type="text" placeholder="Full Name">
                                <mat-error *ngIf="hasError('name', 'required')">Name is required</mat-error>
                            </mat-form-field> 
                        </div> 
                        <div class="uk-margin">
                            <mat-form-field>
                                <input required matInput formControlName="email" class="uk-input" type="email" placeholder="Email">
                                <!-- <mat-error *ngIf="hasError('email', 'required')">Email is required</mat-error> -->
                                <mat-error *ngIf="contactFormGroup.get('email').hasError('email')">Please enter a valid email address</mat-error>
                            </mat-form-field> 
                        </div> 
                        <div class="uk-margin">    
                            <mat-form-field>
                                <input required matInput formControlName="phone" maxlength="15" minlength="10" class="uk-input" type="text" placeholder="Phone">
                                <mat-error *ngIf="hasError('phone', 'required')">Phone is required</mat-error>
                            </mat-form-field> 
                        </div> 
                        <div class="uk-margin">    
                            <mat-form-field>
                                <input required matInput formControlName="position" maxlength="50"   class="uk-input" type="text" placeholder="Required Service">
                                <mat-error *ngIf="hasError('phone', 'required')">Required Service is required</mat-error>
                            </mat-form-field> 
                        </div>
                        <!-- <div class="uk-margin dd_contact">
                            <mat-form-field style="padding-left: 0;" appearance="fill" >
                                <mat-label style="padding-left: 0;">Select Required Service</mat-label>
                                <select style="padding-left: 0;" matNativeControl required formControlName="position">                            
                                    <option value="Software Product Development">Software Product Development</option>
                                        <option value="Mobile App Development">Mobile App Development</option>
                                        <option value="Web Development">Web Development</option>
                                        <option value="Creative Design">Creative Design</option>
                                        <option value="Digital Marketing">Digital Marketing</option>
                                        <option value="SEO">SEO </option>
                                        <option value="Video Animations">Video Animations </option>
                                </select>
                            </mat-form-field>
                        </div>  -->
                        <div class="uk-margin">
                            <mat-form-field class="example-full-width">
                                <mat-label>Leave a comment</mat-label>
                                <textarea required matInput formControlName="comments" placeholder="Ex. It makes me feel..."></textarea>
                              </mat-form-field>

                        </div> 
                        <div class="uk-margin">
                            <button class="uk-button uk-button-secondary qt-btn qt-bg-dark qt-color-white  uk-margin-large" style="margin: 30px 0;" type="submit" [disabled]="!contactFormGroup.valid">Submit</button>
                        </div> 
                    </div>
                </div> 
                
            </div>
        </form>
    </div>
</div>