<div class="uk-position-relative uk-visible-toggle" tabindex="-1" uk-slideshow="ratio: 8:2">
    <ul class="uk-slideshow-items">
        <li>
            <img src="../../assets/images/qafe2.jpg" alt="Canteen Management App for Students" uk-cover>
            <div class="uk-position-bottom uk-position-medium uk-text-left uk-light">
                <div class="uk-container uk-container-large">
                    <h1 class="uk-margin-remove qt-font-bold qafe"  style="color: #000;">Qafe</h1>
                    <p class="uk-margin-remove qt-color-white uk-visible@m" style="color: #000;">Smart Canteen Management Application
                    </p>
                </div>
            </div>
        </li>
    </ul>
    <div class="uk-light">
        <a class="uk-position-center-left uk-position-small uk-hidden-hover" href="#" uk-slidenav-previous uk-slideshow-item="previous"></a>
        <a class="uk-position-center-right uk-position-small uk-hidden-hover" href="#" uk-slidenav-next uk-slideshow-item="next"></a>
    </div>
</div>
 

<!-- <div id="quickatt"></div> -->
<div class="uk-section uk-section-muted qt-bg-white"  >
    <div class="uk-container uk-container-large">
        <div class="uk-child-width-expand@s uk-text-left" uk-grid>
            <div>
                <div class="uk-text-left">
                    
                    <h2 class="qt-font-weight-500  uk-margin-remove-top uk-margin-remove-bottom">Perfect solution for canteen operations
                    </h2>
                    <p>Experience a novel way to manage and supervise the entire operations of a canteen. With an easy-to-use UI and smart features, we bring you Qafe.
                        A state-of-the-art application to manage your canteen services smoothly and efficiently. With our meal management application, the administration will be able to conveniently handle the daily operational activities of a canteen. They can create, edit and publish the Meal Menus in real-time. Users can order their meals even with a smart card. These smart cards can be recharged with cash which are then redeemed by the users for making their canteen purchases. Our solution is perfect for the employee canteen and the school canteen. Parents can easily preorder their kid's meals using our app so that if the kid has any specific food allergy, parents can order their food accordingly.
                        
                    </p>
                   
               
                </div>
            </div>
            <div> 
                <div class="uk-padding uk-padding-remove-bottom uk-padding-remove-left uk-padding-remove-right" style="padding-top: 0;">
                   
                    <div class="uk-padding">
                        <img src="../../assets/images/qafe1.jpg" alt="Qwallet">
                    </div>
                </div>
           </div>
        </div>
    </div>
</div>
 

 


<div ></div>
<div class="uk-section uk-section-muted">
    <div class="uk-container uk-container-large">
  
   
    
            <div class=" uk-text-left " uk-grid >
               <div>
                <div class="uk-text-left">
                    <h2 class="qt-font-weight-500 uk-margin-remove-top uk-margin-remove-bottom"> Feature-rich Dashboard for efficient supervision
                    </h2>
                    <p>The Admin Dashboard is highly intuitive and offers a plethora of advanced features that can help the administration monitor and supervise their canteen operations effectively. Data on canteen attendance, meal distribution, meal consumption patterns etc. can be captured and stored for auditing purposes. With a wide range of reports, the admin team gets increased visibility of food distribution and employee consumption patterns, among other things. Overall, the Meal management application elevates the performance of canteens by upgrading food distribution and enables the management to have increased visibility of their canteen operations.
                    </p>
                 
                </div>
                <br>

                <h2 class="qt-font-weight-500 uk-margin-large uk-margin-remove-top uk-margin-remove-bottom" style="text-align:center;">Features</h2>

                <div class="uk-child-width-expand@s uk-text-left uk-margin-large" uk-grid>
                    <div> 
                        <div class="uk-padding uk-padding-remove-bottom uk-padding-remove-left uk-padding-remove-right" style="padding-top:0;">
                            <div>
                                <h4 class="qt-font-weight-500 uk-margin  uk-margin-remove-bottom">Menu management</h4>
                                <p>Menus can be created under several categories and sub-categories and can be configured on the basis of time slots.   </p>
                                <hr>
                                <h4 class="qt-font-weight-500 uk-margin-large uk-margin-remove-top uk-margin-remove-bottom">Reduce manual overheads</h4>
                                <p>Meals can be distributed quickly as employees can place the orders directly from the application. </p>
                                <hr>
                                <h4 class="qt-font-weight-500 uk-margin-large uk-margin-remove-top uk-margin-remove-bottom">Manage canteens across multiple locations                        </h4>
                                <p>Single login from the admin panel to monitor and supervise the activities of all the canteens under your purview.                        </p>
                                <hr>
                                <h4 class="qt-font-weight-500 uk-margin-large uk-margin-remove-top uk-margin-remove-bottom">Extensive reports   </h4>
                                <p>Detailed analysis of the food distribution pattern based on days, categories, sub-categories, employees etc.                        </p>
                                <hr>   
                            </div>
                        </div>
                   </div>
        
                    <div>
                        <div class="uk-text-left">
                            <!-- <h5 class="qt-color-dark qt-font-bold uk-text-uppercase">Q-wallet</h5> -->
                            <h4 class="qt-font-weight-500 uk-margin  uk-margin-remove-bottom">Automated POS for meal distribution</h4>
                            <p>Avoid waiting time at the counters with a POS device that can hasten the process of ordering food. </p>
                            <hr>
                            <h4 class="qt-font-weight-500 uk-margin-large uk-margin-remove-top uk-margin-remove-bottom">Optimal distribution of food  </h4>
                            <p>Track meal distribution and cease the wastage of food by allowing for optimised food distribution among the employees.      </p>
                            <hr>
                            <h4 class="qt-font-weight-500 uk-margin-large uk-margin-remove-top uk-margin-remove-bottom">Analyse the consumption pattern                    </h4>
                            <p>Management can analyse the consumption of an individual item which would help them to take appropriate decisions.                    </p>
                            <hr>
                            <h4 class="qt-font-weight-500 uk-margin-large uk-margin-remove-top uk-margin-remove-bottom">Pre-order for convenience</h4>
                            <p>Employees can place their orders beforehand for a hassle-free canteen experience.
                            </p>
                            <hr>
                        </div>
                    </div>
                </div>

                <h2 class="qt-font-weight-500 uk-margin-large uk-margin-remove-top uk-margin-remove-bottom" style="text-align:center;">Industries We Serve</h2>

                <div class="uk-child-width-expand@s uk-text-left uk-margin-large" uk-grid>
                    <div> 
                        <div class="uk-padding uk-padding-remove-bottom uk-padding-remove-left uk-padding-remove-right" style="padding-top:0;">
                            <div>
                                <h4 class="qt-font-weight-500 uk-margin  uk-margin-remove-bottom" style="text-align: center;">Employee Canteen</h4>
                                <p style="text-align: center;"><img src="../../assets/images/office.png" alt="Canteen Management App for Students"  ></p>
                            </div>
                        </div>
                   </div>
        
                    <div>
                        <div class="uk-text-left">
                            <!-- <h5 class="qt-color-dark qt-font-bold uk-text-uppercase">Q-wallet</h5> -->
                            <h4 class="qt-font-weight-500 uk-margin  uk-margin-remove-bottom" style="text-align: center;">School Canteen</h4>
                            <p style="text-align: center;"><img src="../../assets/images/school.png" alt="Canteen Management App for Students" ></p>
 
                        </div>
                    </div>
                </div>


 
            </div>
            </div>
 

             <div class=" uk-text-left " uk-grid >
                <div>
                 <div class="uk-text-left">
                     <h3 class="qt-font-weight-500 uk-margin-remove-top uk-margin-remove-bottom"> POS
                     </h3>
                     <p><a href="https://loqqat.com/canteen-management-system" target="_blank">Qafe</a> enables the canteen staff to smartly manage their daily operations smartly, offering maximum productivity. The staff will be equipped with a POS device. Tapping the smart card against this POS device will display the respective menu for that user, from which the user can choose their meal of choice. With cash out of the picture, the handling of the day-to-day transactions made by the users becomes a more smooth process.</p>
                     
                 <ul>
                     <li>Cashless payments</li>
                     <li>POS device for quick and seamless transactions </li>
                     <li>Custom food menus with restricted food items will be displayed</li>
                     <li>Smart card enabled for quick access</li>  
                     <li>Multiple payment options</li>
                 </ul>
                 </div>
             </div>
             </div>
           

    </div>
</div>
 
 
 
 
<app-contactform></app-contactform>