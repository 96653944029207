<div class="uk-section uk-section-secondary uk-darkerest footer-top" id="contact">
    <div class="uk-container">
        <div class="uk-grid-match uk-grid-large uk-child-width-expand@s uk-child-width-1-4@m uk-margin-medium-top" uk-grid>
            <div>
                <div class="uk-margin">
                    <p><img src="../../assets/images/logo_qaptive_white.png"></p>
                    
                    <ul class="uk-list footer-li">
                          
                        <li>
                            <span uk-icon="icon: receiver"></span> 
                            +91 974 609 4422 <br>
                            <span uk-icon="icon: receiver"></span> +91 484 4855790
                        </li>  
                        <li>
                            <span uk-icon="icon: mail"></span> 
                            info@qaptive.co.in
                        </li>  
                    </ul>
                </div> 
            </div>
            <div>  
                <div class="uk-margin">
                    <h4 class="uk-margin-remove accent-text-color">Quick Links</h4>
                    <ul class="uk-list uk-link-text noaro">
                        <li><a class="pointer-small" routerLink="/home"><span uk-icon="arrow-right"></span> Home</a></li>
                        <li><a class="pointer-small" routerLink="/about"><span uk-icon="arrow-right"></span> About Us</a></li> 
                        <li><a class="pointer-small" routerLink="/careers"><span uk-icon="arrow-right"></span> Careers</a></li>  
                        <li><a class="pointer-small" routerLink="/contact"><span uk-icon="arrow-right"></span> Get In Touch</a></li> 
                    </ul>
                </div>
            </div>
            <div>
                <div>
                    <h4 class="uk-margin-remove accent-text-color">Core Services</h4>
                    <ul class="uk-list uk-link-text noaro">
                        <li><a [routerLink]="['software-product-development']"><span uk-icon="arrow-right"></span> Software Product Development
                        </a></li>
                        <li><a [routerLink]="['software-services']"><span uk-icon="arrow-right"></span> Software Services</a></li>
                        <li><a [routerLink]="['it-consulting']"><span uk-icon="arrow-right"></span> IT Consulting</a></li>
                        <li><a [routerLink]="['creative-design-services']"><span uk-icon="arrow-right"></span> Creative Design</a></li>
                    </ul>
                </div>
               
            </div>
            <div>
                <div>
                    <h4 class="uk-margin-remove accent-text-color">Solutions</h4>
                    <ul class="uk-list uk-link-text noaro">
                        <li><a [routerLink]="['healthcare-management-solutions']"><span uk-icon="arrow-right"></span> Healthcare Management 
                        </a></li>
                        <li><a [routerLink]="['education-management-solutions']"><span uk-icon="arrow-right"></span> Education Management 
                        </a></li>
                        <li><a [routerLink]="['fleet-management-solutions']"><span uk-icon="arrow-right"></span> Fleet Management</a></li>
                        <li><a [routerLink]="['hospitality-management-solutions']"><span uk-icon="arrow-right"></span> Hospitality Management 
                        </a></li>
                        <li><a [routerLink]="['budget-management-solutions']"><span uk-icon="arrow-right"></span> Budget Management
                        </a></li>
                    </ul>
                </div>
            </div>
        </div> 
    </div>
    <div class="uk-container">
        <div class="uk-grid-match uk-grid-large uk-child-width-expand@s uk-child-width-1-4@m uk-margin-medium-top" uk-grid>
            <!-- <div>
                <form [formGroup]="newsletterFormGroup" #newsletterForm="ngForm" (ngSubmit)="onSubmit(newsletterForm)">
                <div class="uk-margin">
                    <h4 class="uk-margin uk-margin-remove-top accent-text-color">Newsletter Subscription</h4> 
                    <div class="uk-inline qt-newsletter-wrap">
                      
                        <mat-form-field class="qt-newsletter">
                            <input class="uk-input" formControlName="email" matInput required placeholder="Your Email" type="text">
                            <mat-error *ngIf="newsletterFormGroup.get('email').hasError('email')">Please enter a valid email address</mat-error>
                        </mat-form-field>
                    </div>
                    <div class="uk-margin">
                        <button class="uk-button uk-button-secondary qt-btn qt-bg-black"  type="submit" [disabled]="!newsletterFormGroup.valid">Subscribe</button>
                       
                    </div>
                </div> 
                </form>
            </div> -->
            <div>
                <div>
                    <h4 class="uk-margin-remove accent-text-color">Services</h4>
                    <ul class="uk-list uk-link-text noaro">
                        <li><a [routerLink]="['creative-design']"><span uk-icon="arrow-right"></span>Creative Design</a></li>
                        <li><a [routerLink]="['digital-marketing']"><span uk-icon="arrow-right"></span>Digital Marketing</a></li> 
                        <li><a [routerLink]="['web-design']"><span uk-icon="arrow-right"></span>Web Design </a></li>
                        <li><a [routerLink]="['seo']"><span uk-icon="arrow-right"></span>SEO </a></li>
                        <li><a [routerLink]="['video-animations']"><span uk-icon="arrow-right"></span>Video Animations </a></li>
                    </ul>
                </div>
            </div>
            <div>
                <div>
                    <h4 class="uk-margin-remove accent-text-color">Case Studies</h4>
                    <ul class="uk-list uk-link-text noaro">
                        <li><a [routerLink]="['loqqat-case-study']"><span uk-icon="arrow-right"></span> School Bus Tracking
                        </a></li>
                        <li><a [routerLink]="['peekay-case-study']"><span uk-icon="arrow-right"></span> Healthcare Management
                        </a></li>
                        <li><a [routerLink]="['loqqat-ride-case-study']"><span uk-icon="arrow-right"></span> Employee Ridership</a></li>
                        <li><a [routerLink]="['townbridge-case-study']"><span uk-icon="arrow-right"></span> Hospitality sector
                        </a></li>
                    </ul>
                </div>
            </div>
            <div>
                <div class="uk-margin-small">
                    <h4 class="uk-margin-remove accent-text-color">Social</h4>
                    <ul class="uk-list uk-link-text social-media  ">
                        <li><a class="pointer-small" href="https://www.facebook.com/qaptive" target="_blank"><span uk-icon="facebook"></span> Facebook</a></li> 
                        <li><a class="pointer-small"  href="https://www.instagram.com/qaptive_technologies_pvt_ltd" target="_blank"><span uk-icon="instagram"></span> Instagram</a></li> 
                        <li><a class="pointer-small"  href="https://twitter.com/qaptive?lang=en" target="_blank"><span uk-icon="twitter"></span> Twitter</a></li> 
                        <li><a class="pointer-small"  href="https://www.youtube.com/channel/UCFdM7aw1V50GMXdXZXGtIcg" target="_blank"><span uk-icon="youtube"></span> Youtube</a></li> 
                    </ul>
                </div>
            </div> 
            <div><p>&nbsp;</p></div>
        </div> 
    </div>
</div> 
